<template>
	<!-- 首页 --- zhangqin 2024.3.13 -->
	<div v-loading="loading">
		<div class="home" v-if="size == 'PC'">
			<div class="banner">
				<el-carousel
					ref="carousel"
					indicator-position="none"
					:interval="5000"
					@change="bannerChange"
				>
					<el-carousel-item
						v-for="(item, index) in bannerData"
						:key="index"
					>
						<img class="bannerImg" :src="item.imgUrl" />
					</el-carousel-item>
				</el-carousel>
				<div class="banner-indicator">
					<span
						v-for="(it, ids) in bannerData"
						:key="ids"
						:class="{ activeBanner: bannerId == ids }"
						@click="indicatorChange(ids)"
					></span>
				</div>
			</div>
			<div class="home-about">
				<img class="homea-bg" src="@/assets/imgs/homeAboutBg.png" />
				<div class="homea-cont container flex">
					<div class="homea-left">
						<div class="home-title">
							<div v-if="language == 'zh-cn'">关于我们</div>
							<div class="flex">
								<span
									class="homet-heng"
									v-if="language == 'zh-cn'"
								></span>
								<span :class="{ engSpan: language == 'eng' }"
									>ABOUT US</span
								>
							</div>
						</div>
					</div>
					<div class="homea-right">
						<div class="homear-cont">
							<p v-html="aboutusObj.contentStr"></p>
						</div>
						<div class="homear-num flex">
							<div class="homear-item">
								<div>10+</div>
								<span>{{ $t('m.年经验') }}</span>
							</div>
							<div class="homear-item">
								<div>30</div>
								<span>{{ $t('m.荣誉资质') }}</span>
							</div>
							<div class="homear-item">
								<div>60</div>
								<span>{{ $t('m.专利产品') }}</span>
							</div>
							<div class="homear-item">
								<div>300+</div>
								<span>{{ $t('m.成功产品') }}</span>
							</div>
						</div>
						<div @click="moreUs" class="homear-btn flex">
							<span>{{ $t('m.了解更多') }}</span>
							<img src="@/assets/imgs/homewWhtieMore.png" />
						</div>
					</div>
				</div>
			</div>
			<div class="home-center">
				<div class="container">
					<div class="homec-top flex">
						<div class="home-title">
							<div v-if="language == 'zh-cn'">产品中心</div>
							<div class="flex">
								<span
									class="homet-heng"
									v-if="language == 'zh-cn'"
								></span>
								<span :class="{ engSpan: language == 'eng' }"
									>PRODUCT CENTER</span
								>
							</div>
						</div>
						<div class="home-right">
							<div class="homer-top flex">
								<div
									class="homert-item flex"
									v-for="(item, ids) in oneList"
									:key="ids"
									:class="{ homertActive: ids == yijiId }"
									@click="oneListChange(ids)"
								>
									<div
										class="homert-shu"
										v-if="ids != 0"
									></div>
									<div class="homert-text">{{ item }}</div>
								</div>
							</div>
							<div class="homec-tabs flex">
								<div
									class="homec-item flex"
									v-for="(item, index) in treeList"
									:key="index"
									@click="treeListChange(item, index)"
								>
									<div
										class="homect-shu"
										v-if="index != 0"
									></div>
									<div
										class="homect-text"
										:class="{
											itemActive: index == productId,
										}"
										:style="
											index == treeList.length - 1
												? 'marginRight:0'
												: ''
										"
									>
										{{ item }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="homec-cont flex">
						<div
							class="homecc-item"
							v-for="(ite, ip) in treeDataTabsList.length > 12
								? treeDataTabsList.slice(0, 12)
								: treeDataTabsList"
							:key="ip"
							@click="toDetail(ite.id)"
						>
							<div class="homecc-img">
								<!-- <img src="@/assets/imgs/homeProduct.png" /> -->
								<img
									:src="
										'https://www.wedoco.tech/wedoco-official-pic/' +
										ite.id +
										'.jpg'
									"
								/>
							</div>
							<div class="homecc-tit">
								{{ ite.name }}
							</div>
							<div class="homecc-subtit">
								{{ ite.pl }},{{ ite.erji }}
							</div>
							<div class="homecc-btn flex">
								<span>{{ $t('m.了解详情') }}</span>
								<img src="@/assets/imgs/homewWhtieMore.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="home-product" v-if="allData.length > 0">
				<div class="container flex">
					<div class="hp-left">
						<div class="hpl-top">
							<div>Solution</div>
							<div v-if="language == 'zh-cn'">解决方案展示</div>
						</div>
						<div class="hpl-cont">
							<div>
								{{ allData[recommendaId].title }}
							</div>
							<span>{{ allData[recommendaId].description }}</span>
						</div>
						<div class="hpl-icons flex">
							<div class="hpli-item">
								<img src="@/assets/imgs/homeGX.png" />
								<span>{{ $t('m.高效') }}</span>
							</div>
							<div class="hpli-item">
								<img src="@/assets/imgs/homeJZ.png" />
								<span>{{ $t('m.极致') }}</span>
							</div>
							<div class="hpli-item">
								<img src="@/assets/imgs/homeJZS.png" />
								<span>{{ $t('m.精准') }}</span>
							</div>
						</div>
						<div
							class="hpl-btn flex"
							@click="toBattery(allData[recommendaId].id)"
						>
							<span>{{ $t('m.了解更多') }}</span>
							<img src="@/assets/imgs/homewWhtieMore.png" />
						</div>
					</div>
					<div class="hp-right">
						<div class="hpr-cont flex">
							<img :src="allData[recommendaId].picUrl" />
							<div class="hpr-imgs">
								<img
									src="@/assets/imgs/homeUp.png"
									@click="recChange(1)"
								/>
								<img
									src="@/assets/imgs/homeDown.png"
									@click="recChange(2)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="home-news">
				<div class="container">
					<div class="homen-top flex">
						<div class="home-title">
							<div v-if="language == 'zh-cn'">新闻资讯</div>
							<div class="flex">
								<span
									class="homet-heng"
									v-if="language == 'zh-cn'"
								></span>
								<span :class="{ engSpan: language == 'eng' }"
									>NEWS</span
								>
							</div>
						</div>
						<div class="homen-more">
							<span @click="mores">{{ $t('m.更多') }}</span>
							<img src="@/assets/imgs/homeBlackMore.png" />
						</div>
					</div>
					<div class="homen-cont flex" v-if="newsData.length > 0">
						<div
							class="homenc-left"
							@click="newsDetaile(newsData[0].id)"
						>
							<img :src="newsData[0].picUrl" />
							<div class="flex">
								<div class="homencl-day">
									<div>
										{{
											newsData[0].createTime.substring(
												8,
												10
											)
										}}
									</div>
									<span>{{
										newsData[0].createTime.substring(0, 7)
									}}</span>
								</div>
								<div class="homencl-cont">
									{{ newsData[0].title }}
								</div>
							</div>
						</div>
						<div class="homenc-right flex">
							<div
								class="homencr-item"
								v-for="(item, index) in newsData.slice(0, 4)"
								:key="index"
								@click="newsDetaile(item.id)"
							>
								<div class="item-day">
									{{ item.createTime.substring(8, 10) }}
								</div>
								<div class="item-time">
									{{ item.createTime.substring(0, 7) }}
								</div>
								<div class="item-tit">{{ item.title }}</div>
								<div
									class="item-subtit"
									v-html="item.content"
								></div>
								<img src="@/assets/imgs/homeNewMore.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="homePhone" v-if="size == 'PHONE'">
			<div class="bannerPhone">
				<el-carousel
					ref="carousel"
					indicator-position="none"
					height="414px"
					:interval="5000"
					@change="bannerChange"
				>
					<el-carousel-item
						v-for="(item, index) in bannerData"
						:key="index"
					>
						<img class="bannerImg" :src="item.imgUrl" />
					</el-carousel-item>
				</el-carousel>
				<div class="banner-indicator">
					<span
						v-for="(it, ids) in bannerData"
						:key="ids"
						:class="{ activeBanner: bannerId == ids }"
						@click="indicatorChange(ids)"
					></span>
				</div>
			</div>
			<div class="homeP-cont p16">
				<div class="homeP-item" v-if="allData.length > 0">
					<div class="homePi-tit">
						<p>{{ $t('m.推荐产品') }}</p>
						<span></span>
					</div>
					<div class="homePi-img flex">
						<img :src="allData[recommendaId].picUrl" />
						<div class="hpi-imgs">
							<img
								src="@/assets/imgs/homeUp.png"
								@click="recChange(1)"
							/>
							<img
								src="@/assets/imgs/homeDown.png"
								@click="recChange(2)"
							/>
						</div>
					</div>
					<div class="homePi-text">
						<p>
							{{ allData[recommendaId].title }}
						</p>
						<div class="homo-center">
							{{ allData[recommendaId].description }}
						</div>
						<div class="homp-btn flex">
							<div
								class="flex"
								@click="toBattery(allData[recommendaId].id)"
							>
								<span>{{ $t('m.了解更多') }}</span>
								<img src="@/assets/imgs/homewWhtieMore.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="homeP-item">
					<div class="homePi-tit">
						<p>{{ $t('m.关于我们') }}</p>
						<span></span>
					</div>
					<div class="habout-cont">
						<div class="hac-text">
							<p v-html="aboutusObj.contentStr"></p>
						</div>
						<div class="habout-bom flex">
							<div class="hab-item">
								<p>10+</p>
								<span>{{ $t('m.年经验') }}</span>
							</div>
							<div class="hab-item">
								<p>30</p>
								<span>{{ $t('m.荣誉资质') }}</span>
							</div>
							<div class="hab-item">
								<p>60</p>
								<span>{{ $t('m.专利产品') }}</span>
							</div>
							<div class="hab-item">
								<p>300+</p>
								<span>{{ $t('m.成功产品') }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="homeP-item">
					<div class="homePi-tit">
						<p>{{ $t('m.产品中心') }}</p>
						<span></span>
					</div>
					<div class="hproduct-cont flex">
						<div
							class="hpc-item"
							v-for="(item, index) in phoneList"
							:key="index"
							@click="toDetail(item.fields['物料编号'][0].text)"
						>
							<div class="flex">
								<!-- <img src="@/assets/imgs/homeProduct.png" /> -->
								<img
									:src="
										'https://www.wedoco.tech/wedoco-official-pic/' +
										item.fields['物料编号'][0].text +
										'.jpg'
									"
								/>
							</div>
							<p v-if="language == 'zh-cn'">
								{{ item.fields['物料名称'].value[0].text }}
							</p>
							<p v-else>
								{{ item.fields['Name'].value[0].text }}
							</p>
							<span v-if="language == 'zh-cn'">
								<!-- {{
								item.fields['物料描述'].value[0].text
							}} -->
								{{ item.fields['品类'].value[0] }},{{
									item.fields['二级分类'].value[0]
								}}
							</span>
							<span v-else>
								<!-- {{
								item.fields['物料描述'].value[0].text
							}} -->
								{{ item.fields['品类EN'].value[0].text }},{{
									item.fields['二级分类EN'].value[0].text
								}}
							</span>
						</div>
					</div>
				</div>
				<div class="homeP-item">
					<div class="homePi-tit">
						<div
							class="flex"
							style="justify-content: space-between"
						>
							{{ $t('m.新闻资讯') }}
							<p style="font-size: 12px" @click="mores">
								{{ $t('m.更多') }}
							</p>
						</div>
						<span></span>
					</div>
					<div class="hnews-cont" v-if="newsData.length > 0">
						<div class="hmc-img">
							<img
								@click="newsDetaile(newsData[0].id)"
								:src="newsData[0].picUrl"
							/>
						</div>
						<div class="hnc-top flex">
							<div class="hnct-l">
								<p>
									{{
										newsData[0].createTime.substring(8, 10)
									}}
								</p>
								<span>{{
									newsData[0].createTime.substring(0, 7)
								}}</span>
							</div>
							<div class="hnct-r">
								{{ newsData[0].title }}
							</div>
						</div>
						<div class="hnc-center">
							<div
								class="hncc-item flex"
								v-for="(item, index) in newsData.slice(0, 5)"
								:key="index"
								@click="newsDetaile(item.id)"
							>
								<div class="hncci-l">
									<p>
										{{ item.createTime.substring(8, 10) }}
									</p>
									<span>{{
										item.createTime.substring(0, 7)
									}}</span>
								</div>
								<div class="hncci-r">
									<p class="flex">
										<span>{{ item.title }}</span>
										<img
											src="@/assets/imgs/homeNewMore.png"
										/>
									</p>
									<div class="hncci-text">
										<p v-html="item.content"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getBanner, getaboutus, getNews, getallInfo } from '@/api/home'
export default {
	name: 'home',
	data() {
		return {
			loading: false,
			size: sessionStorage.getItem('size'),
			language: sessionStorage.getItem('language'),
			bannerId: 0,
			productId: 0,
			// recommendationsList: [], //推荐产品列表
			recommendaId: 0, //当前推荐产品
			treeList: [], //三级分类标题列表
			treeDataList: [], //三级分类总共内容列表
			treeDataTabsList: [], //三级分类单独内容列表
			phoneList: [], //手机端产品
			bannerData: [],
			aboutusObj: {},
			newsData: [],
			allData: [],
			oneList: [],
			yijiId: 0,
		}
	},
	created() {
		this.getbannerImg()
		this.getToken()
		this.getallinfoS()
		this.getaboutusData()
		this.getnewsData()
	},
	methods: {
		// 切换品类
		oneListChange(ids) {
			this.yijiId = ids
			let treeList = []
			if (window.sessionStorage.getItem('language') == 'zh-cn') {
				// 获取二级分类
				this.$store.state.productList.map((item) => {
					if (
						item.fields['二级分类'] &&
						item.fields['品类'] &&
						item.fields['品类'].value[0] ==
							this.oneList[this.yijiId]
					) {
						treeList.push(item.fields['二级分类'].value[0])
					}
				})
				treeList = Array.from(new Set(treeList))
				this.treeList = treeList
				console.log(treeList)
				// 获取商品
				let treeDataList = []
				this.treeList.map((it) => {
					treeDataList.push({ label: it, data: [] })
				})
				this.treeDataList = treeDataList
				this.$store.state.productList.map((its) => {
					if (
						its.fields['二级分类'] &&
						this.treeList.indexOf(
							its.fields['二级分类'].value[0]
						) != -1
					) {
						this.treeDataList[
							this.treeList.indexOf(
								its.fields['二级分类'].value[0]
							)
						].data.push({
							name: its.fields['物料名称'].value[0].text,
							subTit: its.fields['物料描述'].value[0].text,
							id: its.fields['物料编号'][0].text,
							erji: its.fields['二级分类'].value[0],
							pl: its.fields['品类'].value[0],
						})
					}
				})
				this.treeDataTabsList = this.treeDataList[0].data
			} else {
				// 获取二级分类
				this.$store.state.productList.map((item) => {
					if (
						item.fields['二级分类EN'] &&
						item.fields['品类EN'] &&
						item.fields['品类EN'].value[0].text ==
							this.oneList[this.yijiId]
					) {
						treeList.push(item.fields['二级分类EN'].value[0].text)
					}
				})
				treeList = Array.from(new Set(treeList))
				this.treeList = treeList
				// 获取商品
				let treeDataList = []
				this.treeList.map((it) => {
					treeDataList.push({ label: it, data: [] })
				})
				this.treeDataList = treeDataList
				this.$store.state.productList.map((its) => {
					if (
						its.fields['二级分类EN'] &&
						this.treeList.indexOf(
							its.fields['二级分类EN'].value[0].text
						) != -1
					) {
						this.treeDataList[
							this.treeList.indexOf(
								its.fields['二级分类EN'].value[0].text
							)
						].data.push({
							name: its.fields['Name']
								? its.fields['Name'].value[0].text
								: '',
							subTit: its.fields['Description']
								? its.fields['Description'].value[0].text
								: '',
							id: its.fields['物料编号'][0].text,
							erji: its.fields['二级分类EN']
								? its.fields['二级分类EN'].value[0].text
								: '',
							pl: its.fields['品类EN']
								? its.fields['品类EN'].value[0].text
								: '',
						})
					}
				})
				this.treeDataTabsList = this.treeDataList[0].data
			}
		},
		// 获取解决方案
		getallinfoS() {
			let data = {
				contentType: 1,
				lng: window.sessionStorage.getItem('language'),
			}
			getallInfo(data).then((res) => {
				this.allData = res.records
			})
		},
		moreUs() {
			this.$router.push('/about')
		},
		mores() {
			this.$router.push('/news')
		},
		newsDetaile(id) {
			this.$router.push({
				path: '/newsDetail',
				query: {
					id: id,
				},
			})
		},
		// 跳转解决方案
		toBattery(id) {
			this.$router.push({
				path: '/allDetaile',
				query: {
					id,
				},
			})
			this.$store.commit('navbgshow', 3)
		},
		// 跳转产品详情
		toDetail(sm) {
			this.$router.push({
				path: '/productDetail',
				query: {
					sm,
				},
			})
			this.$store.commit('navbgshow', 2)
		},
		// 获取新闻列表
		getnewsData() {
			let data = {
				contentType: 10,
				lng: window.sessionStorage.getItem('language'),
			}
			getNews(data).then((res) => {
				if (res.records.length > 0) {
					this.newsData = res.records
				} else {
					this.newsData = []
				}
			})
		},
		// 获取关于我们内容
		getaboutusData() {
			let params = {
				lng: window.sessionStorage.getItem('language'),
			}
			getaboutus(params).then((res) => {
				this.aboutusObj = res
			})
		},
		// 获取轮播banner图
		getbannerImg() {
			if (this.size == 'PC') {
				let data = {
					type: 1,
				}
				getBanner(data).then((res) => {
					this.bannerData = res.records
				})
			} else {
				let data = {
					type: 5,
				}
				getBanner(data).then((res) => {
					this.bannerData = res.records
				})
			}
		},
		// 产品中心切换
		treeListChange(item, ids) {
			this.productId = ids
			this.treeDataTabsList = this.treeDataList[ids].data
		},
		// 推荐产品展示切换
		recChange(type) {
			if (type == 1) {
				if (this.recommendaId == 0) {
					this.recommendaId = this.allData.length - 1
				} else {
					this.recommendaId -= 1
				}
			} else {
				if (this.recommendaId == this.allData.length - 1) {
					this.recommendaId = 0
				} else {
					this.recommendaId += 1
				}
			}
		},
		// 请求用户token和产品数据
		async getToken() {
			this.loading = true
			let { data } = await this.axios.post(
				'api/open-apis/auth/v3/tenant_access_token/internal',
				// `${this.$store.state.feishu}/open-apis/auth/v3/tenant_access_token/internal`,
				{
					app_id: 'cli_a5782ac8eefc100d',
					app_secret: 'qP7u5xpm91bIBwd4gFX7TeZChbxaa6sY',
				},
				{
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
				}
			)
			sessionStorage.setItem('token', data.tenant_access_token)
			sessionStorage.setItem('expirationTime', data.expire)
			// if (sessionStorage.getItem('expirationTime') < 120) {

			if (window.sessionStorage.getItem('language') == 'zh-cn') {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)

				this.$store.commit('productListChange', res.data.data.items)
				// }
				// this.recommendationsList = this.$store.state.productList.slice(
				// 	0,
				// 	20
				// )
				this.phoneList = this.$store.state.productList.slice(0, 4)
				this.loading = false

				let oneList = []
				this.$store.state.productList.map((item) => {
					if (item.fields['品类']) {
						oneList.push(item.fields['品类'].value[0])
					}
				})
				oneList = Array.from(new Set(oneList))
				this.oneList = oneList

				let treeList = []
				this.$store.state.productList.map((item) => {
					if (
						item.fields['二级分类'] &&
						item.fields['品类'] &&
						item.fields['品类'].value[0] ==
							this.oneList[this.yijiId]
					) {
						treeList.push(item.fields['二级分类'].value[0])
					}
				})
				treeList = Array.from(new Set(treeList))
				this.treeList = treeList
				console.log(treeList)
				//  = treeList.slice(3, 9)
				// for (let i = 0; i < 6; i++) {
				// 	let ran = Math.floor(Math.random() * (treeList.length - i))
				// 	this.treeList.push(treeList[ran])
				// 	treeList[ran] = treeList[treeList.length - i - 1]
				// }
				let treeDataList = []
				this.treeList.map((it) => {
					treeDataList.push({ label: it, data: [] })
				})
				this.treeDataList = treeDataList
				this.$store.state.productList.map((its) => {
					if (
						its.fields['二级分类'] &&
						this.treeList.indexOf(
							its.fields['二级分类'].value[0]
						) != -1
					) {
						this.treeDataList[
							this.treeList.indexOf(
								its.fields['二级分类'].value[0]
							)
						].data.push({
							name: its.fields['物料名称'].value[0].text,
							subTit: its.fields['物料描述'].value[0].text,
							id: its.fields['物料编号'][0].text,
							erji: its.fields['二级分类'].value[0],
							pl: its.fields['品类'].value[0],
						})
					}
				})
				this.treeDataTabsList = this.treeDataList[0].data
			} else {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_idEN}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)

				this.$store.commit('productListChange', res.data.data.items)
				// }
				// this.recommendationsList = this.$store.state.productList.slice(
				// 	0,
				// 	20
				// )
				this.phoneList = this.$store.state.productList.slice(0, 4)
				this.loading = false

				let oneList = []
				this.$store.state.productList.map((item) => {
					if (item.fields['品类EN']) {
						oneList.push(item.fields['品类EN'].value[0].text)
					}
				})
				oneList = Array.from(new Set(oneList))
				this.oneList = oneList

				let treeList = []
				this.$store.state.productList.map((item) => {
					if (
						item.fields['二级分类EN'] &&
						item.fields['品类EN'] &&
						item.fields['品类EN'].value[0].text ==
							this.oneList[this.yijiId]
					) {
						treeList.push(item.fields['二级分类EN'].value[0].text)
					}
				})
				treeList = Array.from(new Set(treeList))
				this.treeList = treeList
				//  = treeList.slice(3, 9)
				// for (let i = 0; i < 6; i++) {
				// 	let ran = Math.floor(Math.random() * (treeList.length - i))
				// 	this.treeList.push(treeList[ran])
				// 	treeList[ran] = treeList[treeList.length - i - 1]
				// }
				let treeDataList = []
				this.treeList.map((it) => {
					treeDataList.push({ label: it, data: [] })
				})
				this.treeDataList = treeDataList
				this.$store.state.productList.map((its) => {
					if (
						its.fields['二级分类EN'] &&
						this.treeList.indexOf(
							its.fields['二级分类EN'].value[0].text
						) != -1
					) {
						this.treeDataList[
							this.treeList.indexOf(
								its.fields['二级分类EN'].value[0].text
							)
						].data.push({
							name: its.fields['Name']
								? its.fields['Name'].value[0].text
								: '',
							subTit: its.fields['Description']
								? its.fields['Description'].value[0].text
								: '',
							id: its.fields['物料编号'][0].text,
							erji: its.fields['二级分类EN']
								? its.fields['二级分类EN'].value[0].text
								: '',
							pl: its.fields['品类EN']
								? its.fields['品类EN'].value[0].text
								: '',
						})
					}
				})
				this.treeDataTabsList = this.treeDataList[0].data
			}
		},
		// 轮播图切换
		bannerChange(val) {
			this.bannerId = val
		},
		// 轮播图下标切换
		indicatorChange(ids) {
			this.bannerId = ids
			this.$refs.carousel.setActiveItem(ids)
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__container {
	height: 676px;
}
.home-title {
	margin-top: 40px;
	div {
		font-size: 24px;
		color: #474747;
		font-weight: bold;
	}
	.homet-heng {
		width: 8px;
		height: 2px;
		background-color: #00367a;
		display: inline-block;
		margin-right: 4px;
	}
	span {
		font-size: 22px;
		color: #929292;
		font-weight: 500;
	}
	.engSpan {
		font-size: 24px;
		color: #474747;
		font-weight: bold;
	}
}
.banner {
	width: 100%;
	height: 676px;
	overflow: hidden;
	position: relative;
	.bannerImg {
		width: 100%;
		height: 676px;
	}
	.banner-indicator {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		z-index: 999;
		span {
			display: inline-block;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			margin: 0 7.5px;
			background-color: rgba($color: #ffffff, $alpha: 0.6);
			cursor: pointer;
		}
		.activeBanner {
			width: 13px;
			height: 13px;
			background-color: #284087;
			border: 1px solid #ffffff;
			box-sizing: border-box;
		}
	}
}
.home-product {
	// background: linear-gradient(227deg, #f3f3f3 0%, #ffffff 53%, #f5f5f5 100%);
	background: url('@/assets/imgs/homeProjectBg.png') no-repeat;
	background-size: cover;
	width: 100%;
	height: 568px;
	.hp-left {
		padding: 45px 44px 60px 0;
		box-sizing: border-box;
		// border-right: 1px solid #eaeaea;
		.hpl-top {
			font-size: 24px;
			font-weight: bold;
		}
		.hpl-cont {
			margin-top: 60px;
			width: 469px;
			div {
				font-size: 18px;
				color: #474747;
				font-weight: bold;
			}
			span {
				font-size: 16px;
				color: #9a9999;
				margin-top: 8px;
				display: inline-block;
			}
		}
		.hpl-icons {
			margin-top: 20px;
			.hpli-item {
				margin-right: 47px;
				text-align: center;
			}
			img {
				width: 50px;
				height: 50px;
			}
			span {
				display: block;
				font-size: 14px;
				font-weight: 300;
				color: #474747;
				margin-top: 4px;
			}
		}
		.hpl-btn {
			margin-top: 60px;
			background-color: #284087;
			width: 204px;
			height: 44px;
			justify-content: center;
			color: #ffffff;
			cursor: pointer;
			img {
				width: 14px;
				height: 22px;
				margin-left: 10px;
			}
		}
	}
	.hp-right {
		height: 568px;
		position: relative;
		.hpr-cont {
			position: absolute;
			left: 145px;
			bottom: 67px;
			.hpr-imgs {
				margin-left: 86px;
				img {
					width: 50px;
					height: 50px;
					cursor: pointer;
				}
				img:last-child {
					margin-top: 12px;
				}
			}
		}
		img {
			width: 566px;
			height: 355px;
		}
	}
}
.home-about {
	width: 100%;
	height: 574px;
	position: relative;
	background: url('@/assets/imgs/homeAboutBg.gif') no-repeat;
	background-size: cover;
	.homea-bg {
		width: 1160px;
		height: 100%;
	}
	.homea-cont {
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 99;
		top: 0;
		justify-content: space-between;
		.homea-left {
			height: 100%;
		}
		.homea-right {
			height: 100%;
			padding: 91px 0;
			box-sizing: border-box;
			.homear-cont {
				width: 645px;
				font-size: 18px;
				color: #686670;
			}
			.homear-num {
				margin-top: 40px;
				.homear-item {
					margin-right: 50px;
					div {
						font-size: 30px;
						color: #474747;
						font-weight: bold;
					}
					span {
						font-size: 16px;
						color: #5b5b5b;
					}
				}
			}
			.homear-btn {
				margin-top: 40px;
				background-color: #284087;
				width: 204px;
				height: 44px;
				justify-content: center;
				color: #ffffff;
				cursor: pointer;
				img {
					width: 14px;
					height: 22px;
					margin-left: 10px;
				}
			}
		}
	}
}
.home-center {
	width: 100%;
	padding-top: 25px;
	// padding-bottom: 40px;
	box-sizing: border-box;
	background-color: #f5f5f5;
	.homec-top {
		justify-content: space-between;
		.home-right {
			.homer-top {
				justify-content: flex-end;
				.homert-item {
					border-bottom: 4px solid #dfdfdf;
					padding-bottom: 10px;
					box-sizing: border-box;
					.homert-shu {
						width: 1px;
						height: 11px;
						background-color: #d9d9d9;
					}
					.homert-text {
						text-align: center;
						cursor: pointer;
						width: 96px;
						font-weight: 300;
						font-size: 20px;
						color: #474747;
					}
				}
				.homertActive {
					border-color: #284087;
				}
			}
		}
		.homec-tabs {
			margin-top: 20px;
			.homec-item {
				cursor: pointer;
				font-size: 16px;
				color: #474747;
				font-weight: 300;
				&:hover {
					font-size: 18px;
					font-weight: 500;
				}
				.homect-shu {
					width: 1px;
					height: 11px;
					background-color: #d9d9d9;
				}
				.homect-text {
					margin: 0 30px;
				}
				.itemActive {
					font-size: 18px;
					font-weight: 500;
				}
			}
		}
	}
	.homec-cont {
		margin-top: 20px;
		// justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		.homecc-item {
			width: 200px;
			height: 200px;
			margin-left: 39px;
			background-color: #ffffff;
			padding: 10px;
			padding-bottom: 8px;
			border-radius: 6px;
			margin-bottom: 40px;
			box-sizing: border-box;
			position: relative;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				height: 210px;
				.homecc-btn {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			.homecc-img {
				width: 180px;
				height: 130px;
				text-align: center;
			}
			img {
				width: 123px;
				height: 123px;
			}
			.homecc-tit {
				font-size: 14px;
				font-weight: bold;
				color: #474747;
				margin-top: 7px;
			}
			.homecc-subtit {
				font-size: 12px;
				font-weight: 500;
				color: #727272;
				margin-top: 4px;
			}
			.homecc-btn {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -12px;
				width: 92px;
				height: 25px;
				border-radius: 12px;
				background-color: #284087;
				font-size: 9px;
				color: #ffffff;
				display: none;
				cursor: pointer;
				img {
					width: 9px;
					height: 14px;
					margin-left: 9px;
				}
			}
		}
		.homecc-item:nth-child(6n + 1) {
			margin-left: 0;
		}
	}
}
.home-news {
	width: 100%;
	height: 512px;
	background-color: #f5f5f5;
	.homen-top {
		justify-content: space-between;
		.homen-more {
			font-size: 16px;
			color: #474747;
			font-weight: 300;
			cursor: pointer;
			margin-top: 40px;
			img {
				width: 22px;
				height: 22px;
			}
		}
	}
	.homen-cont {
		justify-content: space-between;
		margin-top: 20px;
		.homenc-left {
			width: 412px;
			cursor: pointer;
			img {
				width: 412px;
				height: 270px;
			}
			.homencl-day {
				width: 80px;
				height: 80px;
				padding: 4px 14px;
				box-sizing: border-box;
				color: #ffffff;
				background-color: #ffb800;
				div {
					font-size: 38px;
					font-weight: bold;
				}
				span {
					font-size: 14px;
					font-weight: 500;
				}
			}
			.homencl-cont {
				flex: 1;
				height: 80px;
				line-height: 80px;
				background-color: #f7f7f7;
				padding-left: 13px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.homenc-right {
			.homencr-item {
				width: 240px;
				height: 350px;
				border-top: 1px solid #ececec;
				border-left: 1px solid #ececec;
				border-bottom: 1px solid #ececec;
				padding-left: 22px;
				box-sizing: border-box;
				padding-top: 34px;
				cursor: pointer;
				.item-day {
					font-size: 38px;
					color: #474747;
					font-weight: bold;
				}
				.item-time {
					font-size: 14px;
					color: #727272;
					font-weight: 500;
				}
				.item-tit {
					font-size: 18px;
					color: #474747;
					font-weight: bold;
					margin-top: 16px;
				}
				.item-subtit {
					width: 197px;
					height: 110px;
					margin-top: 15px;
					font-size: 16px;
					color: #838384;
					display: -webkit-box; /* 必须设置此项才能生效 */
					-webkit-line-clamp: 5; /* 限制最大行数 */
					-webkit-box-orient: vertical; /* 垂直布局 */
					overflow: hidden;
					text-overflow: ellipsis;
					p {
						width: 197px;
						height: 110px;
					}
				}
				img {
					width: 24px;
					height: 24px;
					margin-top: 12px;
				}
				&:hover {
					background: url('@/assets/imgs/homeNewBg.png') no-repeat;
					background-size: cover;
				}
			}
			.homencr-item:last-child {
				border-right: 1px solid #ececec;
			}
		}
	}
}
.homePhone {
	.bannerPhone {
		width: 100%;
		overflow: hidden;
		position: relative;
		.bannerImg {
			width: 100%;
			height: 100%;
		}
		.banner-indicator {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 10px;
			z-index: 99;
			span {
				display: inline-block;
				width: 13px;
				height: 13px;
				border-radius: 50%;
				margin: 0 7.5px;
				background-color: rgba($color: #ffffff, $alpha: 0.6);
				cursor: pointer;
			}
			.activeBanner {
				width: 13px;
				height: 13px;
				background-color: #284087;
				border: 1px solid #ffffff;
				box-sizing: border-box;
			}
		}
	}
	.homeP-cont {
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #f5f5f5;
		.homeP-item {
			padding-top: 20px;
			padding-bottom: 10px;
			box-sizing: border-box;
			background-color: #ffffff;
			border-radius: 14px;
			margin-bottom: 10px;
			.homePi-tit {
				padding: 0 10px;
				box-sizing: border-box;
				p {
					font-size: 18px;
					color: #474747;
					font-weight: bold;
				}
				span {
					display: block;
					width: 15px;
					height: 4px;
					margin-top: 5px;
					background-color: #284087;
				}
			}
			.homePi-img {
				padding-top: 30px;
				padding-bottom: 20px;
				border-bottom: 1px solid #eaeaea;
				box-sizing: border-box;
				justify-content: center;
				position: relative;
				img {
					width: 248px;
					height: 156px;
				}
				.hpi-imgs {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 10px;
					img {
						width: 25px;
						height: 25px;
						cursor: pointer;
						display: block;
					}
					img:last-child {
						margin-top: 5px;
					}
				}
			}
			.homePi-text {
				padding: 0 10px;
				padding-top: 20px;
				box-sizing: border-box;
				p {
					font-size: 14px;
					color: #474747;
					font-weight: bold;
				}
				.homo-center {
					font-size: 11px;
					color: #6a6464;
					margin-top: 10px;
				}
				.homp-btn {
					justify-content: flex-end;
					margin-top: 10px;
					div {
						width: 80px;
						height: 22px;
						background-color: #284087;
						border-radius: 3px;
						color: #ffffff;
						font-size: 10px;
						font-weight: 500;
						justify-content: center;
						img {
							width: 10px;
							height: 16px;
							margin-left: 2px;
						}
					}
				}
			}
		}
		.homeP-item:last-child {
			margin-bottom: 0;
		}

		.habout-cont {
			margin-top: 20px;
			padding: 0 10px;
			box-sizing: border-box;
			.hac-text {
				font-size: 11px;
				color: #6a6464;
				font-weight: 500;
			}
			.habout-bom {
				margin-top: 3px;
				padding: 20px;
				padding-right: 29px;
				box-sizing: border-box;
				background: rgba(40, 64, 135, 0.03);
				justify-content: space-between;
				border-radius: 12px;
				p {
					font-size: 18px;
					color: #474747;
					font-weight: bold;
				}
				span {
					color: #5b5b5b;
					font-size: 10px;
					display: block;
				}
			}
		}
		.hproduct-cont {
			padding: 0 10px;
			box-sizing: border-box;
			margin-top: 20px;
			flex-wrap: wrap;
			justify-content: space-between;
			.hpc-item {
				width: 165px;
				height: 189px;
				border: 1px solid #ededed;
				padding: 0 10px;
				padding-top: 23px;
				box-sizing: border-box;
				margin-bottom: 10px;
				border-radius: 3px;
				div {
					justify-content: center;
				}
				img {
					width: 105px;
					height: 105px;
				}
				p {
					font-size: 10px;
					font-weight: bold;
					color: #474747;
					margin-top: 17px;
				}
				span {
					font-weight: 500;
					font-size: 9px;
					color: #727272;
					display: block;
					margin-top: 3px;
				}
			}
		}
		.hnews-cont {
			padding: 0 10px;
			box-sizing: border-box;
			margin-top: 10px;
			.hmc-img {
				width: 100%;
				height: 110px;
				overflow: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: 100%;
				}
			}
			.hnc-top {
				.hnct-l {
					width: 40px;
					height: 40px;
					background-color: #ffb800;
					color: #ffffff;
					text-align: center;
					p {
						font-weight: bold;
						font-size: 19px;
						margin-top: 2px;
					}
					span {
						font-weight: 500;
						font-size: 7px;
						display: block;
					}
				}
				.hnct-r {
					flex: 1;
					width: 100%;
					background-color: #f7f7f7;
					height: 40px;
					font-weight: bold;
					font-size: 9px;
					color: #474747;
					line-height: 40px;
					padding-left: 7px;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.hnc-center {
				.hncc-item {
					padding: 20px 0;
					box-sizing: border-box;
					justify-content: space-between;
					border-bottom: 1px solid #f5f5f5;
					.hncci-l {
						width: 40px;
						height: 40px;
						text-align: center;
						p {
							font-weight: bold;
							font-size: 19px;
							color: #474747;
						}
						span {
							font-weight: 500;
							font-size: 7px;
							color: #727272;
							display: block;
						}
					}
					.hncci-r {
						flex: 1;
						width: 100%;
						p {
							justify-content: space-between;
							padding-right: 10px;
							box-sizing: border-box;
							img {
								width: 12px;
								height: 12px;
							}
							span {
								font-weight: bold;
								font-size: 9px;
								color: #474747;
							}
						}
						.hncci-text {
							font-weight: 500;
							font-size: 8px;
							color: #838384;
							margin-top: 5px;
							width: 100%;
							height: 25px;
							display: -webkit-box; /* 必须设置此项才能生效 */
							-webkit-line-clamp: 2; /* 限制最大行数 */
							-webkit-box-orient: vertical; /* 垂直布局 */
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
				.hncc-item:last-child {
					border-bottom: 0;
					padding-bottom: 10px;
				}
			}
		}
	}
}
</style>
