<template>
	<!-- 顶部导航栏 --- zhangqin 2024.3.13 -->
	<div class="app-topNav">
		<div class="app-cont flex container">
			<div class="topNav-left flex">
				<div
					class="topNav-item flex"
					v-for="(item, index) in navList"
					:key="index"
					@click="navChange(item)"
					@mouseenter="navMouseenter(item)"
					@mouseleave="
						;($store.state.navShow = $store.commit(
							'navHoverShow',
							$store.state.navbg
						)),
							(proShow = false)
					"
				>
					<span class="topNav-shu" v-if="index != 0"></span>
					<div
						class="topNav-text"
						:class="{ navActive: $store.state.navbg == item.value }"
					>
						<span>{{ item.name }}</span>
						<div
							class="topNav-heng"
							v-if="
								$store.state.navbg == item.value ||
								$store.state.navShow == item.value
							"
						></div>
					</div>
				</div>
			</div>
			<div class="topNav-right flex">
				<div
					class="topNavr-item flex"
					@click="searchShow = !searchShow"
				>
					<img src="@/assets/imgs/homeSeach.png" />
					<span>{{ $t('m.search') }}</span>
				</div>
				<span class="topNav-shu"></span>
				<el-dropdown trigger="click" @command="changeLangEvent">
					<div class="topNavr-item flex">
						<img src="@/assets/imgs/homeLanguage.png" />
						<span>{{ $t('m.language') }}</span>
						<img
							class="topNavl-bom"
							src="@/assets/imgs/homeBom.png"
						/>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="zh-cn"
							>中文</el-dropdown-item
						>
						<el-dropdown-item command="eng"
							>English</el-dropdown-item
						>
					</el-dropdown-menu>
				</el-dropdown>

				<div class="topNav-logo">
					<img src="@/assets/imgs/homeLogo.png" />
				</div>
			</div>
			<!-- v-show="proShow || proShow2" -->
			<div
				class="topNav-drop flex"
				v-show="proShow || proShow2"
				@mouseenter="proShow2 = true"
				@mouseleave="proShow2 = false"
			>
				<div class="topNavd-left" v-if="twoProList.length > 0">
					<div class="topNavl-all flex">
						<img src="@/assets/imgs/topNavAll.png" />
						<span>{{ $t('m.全部') }} {{ proTitle }}</span>
					</div>
					<div class="topNavl-pro" v-if="proTitle == $t('m.产品')">
						<div
							class="topNavlp-item"
							v-for="(item, index) in oneProList"
							:key="index"
							@click="oneProListChange(item, index)"
						>
							<div class="flex">
								<span
									:class="{
										itemSpanActive: index == oneProId,
									}"
									>{{ item }}</span
								>
								<img
									src="@/assets/imgs/topNavActiveMore.png"
									v-if="index == oneProId"
								/>
								<img
									src="@/assets/imgs/topNavMore.png"
									v-else
								/>
							</div>
						</div>
					</div>

					<div class="topNavl-pro" v-else>
						<div
							class="topNavlp-item"
							v-for="(item, index) in proList"
							:key="index"
							@mouseenter="proId = item.value"
							@mouseleave="proId = null"
							@click="toPush(item)"
						>
							<div class="flex">
								<span
									:class="{
										itemSpanActive: item.value == proId,
									}"
									>{{ item.name }}</span
								>
								<img
									src="@/assets/imgs/topNavActiveMore.png"
									v-if="item.value == proId"
								/>
								<img
									src="@/assets/imgs/topNavMore.png"
									v-else
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					class="topNavd-right"
					v-if="
						(proTitle == $t('m.产品') &&
							twoProList.length > 0 &&
							oneProId) ||
						oneProId == 0
					"
				>
					<div
						class="topNavdr-item flex"
						@click="twoProListChange(item, index)"
						v-for="(item, index) in twoProList[oneProId].data"
						:key="index"
					>
						<div :class="{ itemSpanActive: index == twoProId }">
							{{ item }}
						</div>
						<img
							src="@/assets/imgs/topNavActiveMore.png"
							v-if="index == twoProId"
						/>
						<img src="@/assets/imgs/topNavMore.png" v-else />
					</div>
				</div>
				<div
					class="topNavd-rightTree"
					v-if="
						(proTitle == $t('m.产品') &&
							treeProList.length > 0 &&
							twoProId) ||
						twoProId == 0
					"
				>
					<div
						class="topNavdr-item"
						@click="treeProChange(item, index)"
						v-for="(item, index) in treeProListActive"
						:key="index"
					>
						<div :class="{ itemSpanActive: index == treeProId }">
							{{ item }}
						</div>
					</div>
				</div>
				<div class="topNavd-left" style="opacity: 0; width: 500px">
					<div class="topNavl-all flex">
						<img src="@/assets/imgs/topNavAll.png" />
						<span>{{ $t('m.全部') }} {{ proTitle }}</span>
					</div>
					<div class="topNavl-pro" v-if="proTitle == $t('m.产品')">
						<div
							class="topNavlp-item"
							v-for="(item, index) in oneProList"
							:key="index"
							@click="oneProListChange(item, index)"
						>
							<div class="flex">
								<span
									:class="{
										itemSpanActive: index == oneProId,
									}"
									>{{ item }}</span
								>
								<img
									src="@/assets/imgs/topNavActiveMore.png"
									v-if="index == oneProId"
								/>
								<img
									src="@/assets/imgs/topNavMore.png"
									v-else
								/>
							</div>
						</div>
					</div>

					<div class="topNavl-pro" v-else>
						<div
							class="topNavlp-item"
							v-for="(item, index) in proList"
							:key="index"
							@mouseenter="proId = item.value"
							@mouseleave="proId = null"
							@click="toPush(item)"
						>
							<div class="flex">
								<span
									:class="{
										itemSpanActive: item.value == proId,
									}"
									>{{ item.name }}</span
								>
								<img
									src="@/assets/imgs/topNavActiveMore.png"
									v-if="item.value == proId"
								/>
								<img
									src="@/assets/imgs/topNavMore.png"
									v-else
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="topNav-search flex" v-if="searchShow">
				<input
					type="text"
					:placeholder="$t('m.您想查询的产品是什么')"
					v-model="searchVal"
					@keyup.enter="searchChange"
				/>
				<div class="topNavs-btn flex" @click="searchChange">
					<span>{{ $t('m.search') }}</span>
					<img src="@/assets/imgs/topNavSearch.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'topNav',
	data() {
		return {
			searchVal: '',
			navList: [
				{ name: this.$t('m.首页'), value: 1, path: '/' },
				{ name: this.$t('m.产品'), value: 2, path: '/product' },
				{ name: this.$t('m.解决方案'), value: 3, path: '/battery' },
				{ name: this.$t('m.支持'), value: 4, path: '/information' },
				{ name: this.$t('m.关于'), value: 5, path: '/about' },
			],
			navId: 1,
			navShow: 1,
			searchShow: false,
			proShow: false,
			proShow2: false,
			proTitle: '',
			proList: [],
			proId: null,
			oneProList: [], //一级分类列表
			oneProId: null, //选择的一级分类
			twoProList: [], //二级分类列表
			twoProId: null, //选择的二级分类
			treeProList: [], //三级分类总列表
			treeProListActive: [], //当前三级分类列表
			treeProId: null, //选择的三级分类
			oneProName: '零部件', //选择的一级分类名称
			twoProName: '', //选择的二级分类名称
			treeProName: '', //选择的三级分类名称
		}
	},
	created() {
		if (!sessionStorage.getItem('language')) {
			window.sessionStorage.setItem('language', 'zh-cn')
		}
		this.getToken()
	},
	methods: {
		// 搜索框
		searchChange() {
			if (!this.searchVal) {
				this.$message.error('请输入搜索内容!')
				return
			}
			let query = {
				name: '',
				id: '',
			}
			var reg = new RegExp('^[0-9]*$')
			if (!reg.test(this.searchVal)) {
				//obj.value为输入框或其他标签的内容
				query.name = this.searchVal
			} else {
				query.id = this.searchVal
			}
			if (this.$route.path != '/product') {
				this.$router.push({
					path: '/product',
					query,
				})
			} else {
				if (
					this.$route.query.name == this.searchVal ||
					this.$route.query.id == this.searchVal
				) {
					this.$router.go(0)
				} else {
					this.$router.push({
						path: '/product',
						query,
					})
					this.$router.go(0)
				}
			}
			this.$store.commit('navbgshow', 2)
			this.searchShow = false
			this.searchVal = ''
		},
		// 三级分类切换
		treeProChange(item, ids) {
			console.log(this.oneProName)
			this.treeProName = item
			this.$store.commit('navbgshow', 2)
			this.twoProId = null
			if (this.$route.path != '/product') {
				this.$router.push({
					path: '/product',
					query: {
						one: this.oneProName,
						two: this.twoProName,
						tree: item,
					},
				})
			} else {
				if (this.$route.query.tree == item) {
					this.$router.go(0)
				} else {
					this.$router.push({
						path: '/product',
						query: {
							one: this.oneProName,
							two: this.twoProName,
							tree: item,
						},
					})
					this.$router.go(0)
				}
			}
			this.proShow2 = false
		},
		// 二级分类切换
		twoProListChange(item, ids) {
			this.twoProId = ids
			this.twoProName = item
			let arr = []
			this.treeProList.map((it) => {
				if (it.label == item) {
					arr = arr.concat(it.data)
				}
			})
			this.treeProListActive = arr
		},
		// 一级分类切换
		oneProListChange(item, ids) {
			this.oneProId = ids
			this.oneProName = item
			this.twoProId = null
		},
		// 请求用户token和产品数据
		async getToken() {
			let { data } = await this.axios.post(
				'api/open-apis/auth/v3/tenant_access_token/internal',
				// `${this.$store.state.feishu}/open-apis/auth/v3/tenant_access_token/internal`,
				{
					app_id: 'cli_a5782ac8eefc100d',
					app_secret: 'qP7u5xpm91bIBwd4gFX7TeZChbxaa6sY',
				},
				{
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
				}
			)
			sessionStorage.setItem('token', data.tenant_access_token)
			sessionStorage.setItem('expirationTime', data.expire)
			// if (sessionStorage.getItem('expirationTime') < 120) {

			if (window.sessionStorage.getItem('language') == 'zh-cn') {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)
				let list = res.data.data.items

				let erji = [],
					yc = [],
					gl = []
				list.map((y) => {
					if (y.fields['二级分类']) {
						erji.push({
							val: y.fields['二级分类'].value[0],
							type: '二级分类',
						})
					}
					if (y.fields['原厂品牌']) {
						yc.push({
							val: y.fields['原厂品牌'].value[0],
							type: '原厂品牌',
						})
					}
					if (y.fields['关联产品']) {
						gl.push({
							val: y.fields['关联产品'].value[0],
							type: '关联产品',
						})
					}
				})
				erji = Array.from(new Set(erji))
				yc = Array.from(new Set(yc))
				gl = Array.from(new Set(gl))
				erji = this.fn1(erji)
				yc = this.fn1(yc)
				gl = this.fn1(gl)

				let all = erji.concat(yc).concat(gl)
				sessionStorage.setItem('all', JSON.stringify(all))

				let oneProList = []
				list.map((one) => {
					if (one.fields['品类']) {
						oneProList.push(one.fields['品类'].value[0])
					}
				})
				oneProList = Array.from(new Set(oneProList))
				this.oneProList = oneProList

				let twoProList = []
				this.oneProList.map((iv) => {
					twoProList.push({ label: iv, data: [] })
				})
				list.map((item) => {
					if (
						item.fields['品类'] &&
						this.oneProList.indexOf(item.fields['品类'].value[0]) !=
							-1 &&
						item.fields['二级分类']
					) {
						// twoProList.push(item.fields['二级分类'].value[0])
						twoProList[
							this.oneProList.indexOf(
								item.fields['品类'].value[0]
							)
						].data.push(item.fields['二级分类'].value[0])
					}
				})
				twoProList.map((v) => {
					v.data = Array.from(new Set(v.data))
				})

				this.twoProList = twoProList

				let treeProList = [],
					arr = [] //把二级分类下的数据全取出来
				this.twoProList.map((its) => {
					its.data.map((ic) => {
						arr.push(ic)
						treeProList.push({ label: ic, data: [] })
					})
				})

				list.map((it) => {
					if (
						it.fields['二级分类'] &&
						arr.indexOf(it.fields['二级分类'].value[0]) != -1 &&
						it.fields['三级分类']
					) {
						treeProList[
							arr.indexOf(it.fields['二级分类'].value[0])
						].data.push(it.fields['三级分类'].value[0])
					}
				})
				treeProList.map((i) => {
					i.data = Array.from(new Set(i.data))
				})
				this.treeProList = treeProList
			} else {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_idEN}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)
				let list = res.data.data.items

				let erji = [],
					yc = [],
					gl = []
				list.map((y) => {
					if (y.fields['二级分类EN']) {
						erji.push({
							val: y.fields['二级分类EN'].value[0].text,
							type: '二级分类EN',
						})
					}
					if (y.fields['原厂品牌']) {
						yc.push({
							val: y.fields['原厂品牌'].value[0],
							type: '原厂品牌',
						})
					}
					if (y.fields['关联产品EN']) {
						gl.push({
							val: y.fields['关联产品EN'].value[0].text,
							type: '关联产品EN',
						})
					}
				})
				erji = Array.from(new Set(erji))
				yc = Array.from(new Set(yc))
				gl = Array.from(new Set(gl))
				erji = this.fn1(erji)
				yc = this.fn1(yc)
				gl = this.fn1(gl)

				let all = erji.concat(yc).concat(gl)
				sessionStorage.setItem('all', JSON.stringify(all))

				let oneProList = []
				list.map((one) => {
					if (one.fields['品类EN']) {
						oneProList.push(one.fields['品类EN'].value[0].text)
					}
				})
				oneProList = Array.from(new Set(oneProList))
				this.oneProList = oneProList

				let twoProList = []
				this.oneProList.map((iv) => {
					twoProList.push({ label: iv, data: [] })
				})
				list.map((item) => {
					if (
						item.fields['品类EN'] &&
						this.oneProList.indexOf(
							item.fields['品类EN'].value[0].text
						) != -1 &&
						item.fields['二级分类EN']
					) {
						// twoProList.push(item.fields['二级分类'].value[0])
						twoProList[
							this.oneProList.indexOf(
								item.fields['品类EN'].value[0].text
							)
						].data.push(item.fields['二级分类EN'].value[0].text)
					}
				})
				twoProList.map((v) => {
					v.data = Array.from(new Set(v.data))
				})

				this.twoProList = twoProList

				let treeProList = [],
					arr = [] //把二级分类下的数据全取出来
				this.twoProList.map((its) => {
					its.data.map((ic) => {
						arr.push(ic)
						treeProList.push({ label: ic, data: [] })
					})
				})

				list.map((it) => {
					if (
						it.fields['二级分类EN'] &&
						arr.indexOf(it.fields['二级分类EN'].value[0].text) !=
							-1 &&
						it.fields['三级分类EN']
					) {
						treeProList[
							arr.indexOf(it.fields['二级分类EN'].value[0].text)
						].data.push(it.fields['三级分类EN'].value[0].text)
					}
				})
				treeProList.map((i) => {
					i.data = Array.from(new Set(i.data))
				})
				this.treeProList = treeProList
			}
		},
		// 语言切换
		changeLangEvent(command) {
			this.$i18n.locale = command
			sessionStorage.setItem('language', command)
			this.$router.go(0)
			// if (command === 'zh-CN') {
			// 	// command = 'eng'
			// 	this.$i18n.locale = 'eng'
			// } else {
			// 	// command = 'zh-CN'
			// 	this.$i18n.locale = 'zh-CN'
			// }
		},
		// 鼠标移入导航栏
		navMouseenter(item) {
			// this.searchShow = false
			this.$store.commit('navHoverShow', item.value)
			if (item.value == 3) {
				this.oneProId = null
				this.twoProId = null
				this.proList = [
					{
						name: this.$t('m.电池行业'),
						value: 1,
						navbg: 3,
						path: '/battery',
					},
					{
						name: this.$t('m.汽车行业'),
						value: 2,
						navbg: 3,
						path: '/car',
					},
					{
						name: this.$t('m.一般行业'),
						value: 3,
						navbg: 3,
						path: '/sameAs',
					},
				]
			} else if (item.value == 4) {
				this.oneProId = null
				this.twoProId = null
				this.proList = [
					{
						name: this.$t('m.设备故障处理'),
						navbg: 4,
						value: 1,
						path: '/problemHandling',
					},
					{
						name: this.$t('m.证书认证'),
						navbg: 4,
						value: 2,
						path: '/certificate',
					},
					{
						name: this.$t('m.设备资料'),
						navbg: 4,
						value: 3,
						path: '/information',
					},
				]
			} else if (item.value == 5) {
				this.oneProId = null
				this.twoProId = null
				this.proList = [
					{
						name: this.$t('m.关于我们'),
						value: 1,
						navbg: 5,
						path: '/about',
					},
					{
						name: this.$t('m.联系我们'),
						value: 2,
						navbg: 5,
						path: '/contactUs',
					},
					{
						name: this.$t('m.联系经销商'),
						value: 3,
						navbg: 5,
						path: '/dealer',
					},
				]
			}
			if (item.value != 1) {
				this.proTitle = item.name
				this.proShow = true
			} else {
				this.proShow = false
			}
		},
		// 跳转页面
		toPush(item) {
			this.$store.commit('navbgshow', item.navbg)
			if (this.$route.path != item.path) {
				this.$router.push(item.path)
			}
			this.proShow2 = false
		},
		// 点击导航栏
		navChange(item) {
			this.$store.commit('navbgshow', item.value)
			if (this.$route.path != item.path) {
				this.$router.push(item.path)
			}
			this.proShow = false
		},
		// 数组对象去重
		fn1(tempArr) {
			for (let i = 0; i < tempArr.length; i++) {
				for (let j = i + 1; j < tempArr.length; j++) {
					if (tempArr[i].val == tempArr[j].val) {
						tempArr.splice(j, 1)
						j--
					}
				}
			}
			return tempArr
		},
	},
}
</script>
<style scoped lang="scss">
.app-topNav {
	height: 140px;
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 999;
	.app-cont {
		justify-content: space-between;
		font-size: 20px;
		font-weight: 500;
		position: relative;
	}
	.topNav-drop {
		position: fixed;
		top: 140px;
		// bottom: -207px;
		// width: 100%;
		// height: 207px;
		z-index: 999;
		font-size: 16px;
		color: #474747;
		font-weight: 500;
		align-items: flex-start;
		.itemSpanActive {
			color: #00367a;
		}

		.topNavd-left {
			// width: 435px;
			// height: 207px;
			// padding-left: 20px;
			// padding-top: 20px;
			background-color: #fff;
			padding: 20px;
			padding-bottom: 12px;
			box-sizing: border-box;
			.topNavl-all {
				font-weight: bold;
				margin-bottom: 12px;
				img {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}
			}
			.topNavl-pro {
				padding-left: 24px;
				box-sizing: border-box;
				width: 100%;
				// height: 165px;
				overflow-y: auto;
				.topNavlp-item {
					cursor: pointer;
					.topNavlp-heng {
						width: 6px;
						height: 1px;
						background-color: #474747;
					}
					span {
						display: inline-block;
						width: 180px;
						margin: 8px 0;
					}

					img {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.topNavd-rightTree {
			width: 237px;
			background-color: #eeeeee;
			border-left: 1px solid #eaeaea;
			max-height: 290px;
			padding-left: 20px;
			padding-top: 62px;
			padding-right: 20px;
			padding-bottom: 20px;
			box-sizing: border-box;
			overflow-y: auto;
			.topNavdr-item {
				margin-bottom: 20px;
				div {
					cursor: pointer;
				}
			}
		}
		.topNavd-right {
			// width: 530px;
			background-color: #f8f8f8;
			border-left: 1px solid #eaeaea;
			// height: 207px;
			max-height: 290px;
			padding-left: 20px;
			padding-top: 62px;
			padding-right: 20px;
			box-sizing: border-box;
			overflow-y: auto;
			padding-bottom: 20px;
			.topNavdr-item {
				margin-bottom: 20px;
				cursor: pointer;
				div {
					width: 180px;
				}
				img {
					width: 16px;
					height: 16px;
				}
			}
		}
		.toNavd-rightAll {
			width: 700px;
			background-color: #474747;
			// height: 300px;
		}
	}
	.topNav-search {
		position: absolute;
		width: 100%;
		height: 90px;
		background-color: #ffffff;
		left: 0;
		bottom: -90px;
		z-index: 99;
		padding: 20px;
		padding-left: 24px;
		box-sizing: border-box;
		input {
			flex: 1;
			border: 1px solid #e0e0e0;
			height: 50px;
			box-sizing: border-box;
			padding-left: 13px;
			font-size: 16px;
			color: #474747;
		}
		.topNavs-btn {
			width: 132px;
			height: 50px;
			background-color: #284087;
			font-size: 18px;
			color: #ffffff;
			justify-content: center;
			margin-left: 20px;
			cursor: pointer;
			img {
				width: 18px;
				height: 18px;
				margin-left: 26px;
			}
		}
	}
}
.topNav-shu {
	width: 1px;
	height: 11px;
	background-color: #d9d9d9;
}
.topNav-left {
	color: #808080;
	.topNav-item {
		cursor: pointer;
		&:hover {
			color: #474747;
			font-size: 22px;
			font-weight: 800;
		}
		.topNav-text {
			position: relative;
			margin: 0 40px;
			height: 140px;
			line-height: 140px;
		}
		.navActive {
			color: #474747;
			font-size: 22px;
			font-weight: 800;
		}
		.topNav-heng {
			position: absolute;
			width: 72px;
			height: 3px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0px;
			background-color: #284087;
		}
	}
	.topNav-item:first-child {
		.topNav-text {
			margin-left: 20px;
		}
	}
}
.topNav-right {
	.topNavr-item {
		margin: 0 40px;
		cursor: pointer;
		span {
			margin: 0 4px;
			font-size: 20px;
			font-weight: 500;
			color: #474747;
		}
		img {
			width: 19px;
			height: 19px;
		}
		.topNavl-bom {
			width: 10px;
			height: 10px;
			margin-left: 4px;
		}
	}
	.topNav-logo {
		margin-left: 40px;
		img {
			width: 184px;
			height: 38px;
		}
	}
}
</style>
