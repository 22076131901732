import axios from 'axios'
import Vue from 'vue'
import Router from '@/router'
import { Message } from 'element-ui'

const service = axios.create({
	// baseURL: 'http://211.149.137.28:8005',
	baseURL: 'https://www.wedoco.tech/blapis',
	timeout: 10000,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
	},
})

// 请求拦截
service.interceptors.request.use(
	(config) => {
		config.headers.Authorization = localStorage.getItem('token')
		if (config.data) {
			// config.data = qs.stringify(config.data)
		} else {
			config.data = {}
		}
		return config
	},
	(error) => {
		console.log(error)
		return Promise.reject(error)
	}
)

// 返回拦截
service.interceptors.response.use(
	(response) => {
		const res = response.data
		if (res.code == 0) {
			return Promise.resolve(res.data)
		} else if (res.code == 500) {
			Message({
				type: 'error',
				message: res.data,
				duration: 3000,
			})
		}
		return Promise.reject(res.message)
	},
	(error) => {
		Message({
			type: 'error',
			message: error.message,
			duration: 3000,
		})
		return Promise.reject(new Error(error.message || 'error'))
	}
)

export default service
