import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
	// 首页
	{
		path: '/',
		name: 'home',
		component: home,
	},
	// 关于我们
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/AboutView.vue'),
	},
	// 联系我们
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import('../views/contactUs.vue'),
	},
	// 联系经销商
	{
		path: '/dealer',
		name: 'dealer',
		component: () => import('../views/dealer.vue'),
	},
	// 产品
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product.vue'),
	},
	// 产品详情
	{
		path: '/productDetail',
		name: 'productDetail',
		component: () => import('../views/productDetail.vue'),
	},
	// 解决方案-电池行业
	{
		path: '/battery',
		name: 'battery',
		component: () => import('../views/battery.vue'),
	},
	// 解决方案-汽车行业
	{
		path: '/car',
		name: 'car',
		component: () => import('../views/car.vue'),
	},
	// 解决方案-一般行业
	{
		path: '/sameAs',
		name: 'sameAs',
		component: () => import('../views/sameAs.vue'),
	},
	// 解决方案通用详情页面
	{
		path: '/allDetaile',
		name: 'allDetaile',
		component: () => import('../views/allDetaile.vue'),
	},
	// 支持-设备故障处理
	{
		path: '/problemHandling',
		name: 'problemHandling',
		component: () => import('../views/problemHandling.vue'),
	},
	// 支持-证书&认证
	{
		path: '/certificate',
		name: 'certificate',
		component: () => import('../views/certificate.vue'),
	},
	// 支持-设备资料
	{
		path: '/information',
		name: 'information',
		component: () => import('../views/information.vue'),
	},
	// 支持通用详情页面
	{
		path: '/supportDetaile',
		name: 'supportDetaile',
		component: () => import('../views/supportDetaile.vue'),
	},
	// 新闻
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news.vue'),
	},
	// 新闻详情
	{
		path: '/newsDetail',
		name: 'newsDetail',
		component: () => import('../views/newsDetail.vue'),
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})

export default router
