export const m = {
	首页: '首页',
	产品: '产品',
	解决方案: '解决方案',
	支持: '支持',
	关于: '关于',
	search: '搜索',
	language: '中文',
	高效: '高效',
	极致: '极致',
	精准: '精准',
	了解更多: '了解更多',
	年经验: '年经验',
	荣誉资质: '荣誉资质',
	专利产品: '专利产品',
	成功产品: '成功产品',
	了解详情: '了解详情',
	更多: '更多',
	关注我们: '关注我们',
	快捷导航: '快捷导航',
	联系方式: '联系方式',
	我们支持可为您提供24小时全天候的帮助:
		'我们支持可为您提供24小时全天候的帮助。',
	地址: '重庆市渝北区仙桃街道数据谷中路38号C17-1栋第1层',
	电池行业: '电池行业',
	汽车行业: '汽车行业',
	一般行业: '一般行业',
	设备故障处理: '设备故障处理',
	证书认证: '证书&认证',
	设备资料: '设备资料',
	关于我们: '关于我们',
	联系我们: '联系我们',
	联系经销商: '联系经销商',
	全部: '全部',
	您想查询的产品是什么: '您想查询的产品/物料编号是什么？',
	您当前的位置: '您当前的位置',
	筛选: '筛选',
	特定目录: '特定目录',
	显示更多: '显示更多',
	显示更少: '显示更少',
	选择您的产品: '选择您的产品',
	产品编号: '产品编号',
	品类: '品类',
	新闻资讯: '新闻资讯',
	新闻详情: '新闻详情',
	发布时间: '发布时间',
	来源: '来源',
	产品中心: '产品中心',
	推荐产品: '推荐产品',
	产品简介: '产品简介',
	原厂编号: '原厂编号',
	关联编号: '关联编号',
	关联产品: '关联产品',
	作为国际化的市场领导者:
		'维多科流体技术（重庆）有限公司位于重庆市渝北区，提供用于汽车、锂电池及一般工业生产所需的流体设备及配件',
	电子产品销售:
		'电子产品销售;电子元器件批发;电工仪器仪表销售;计算机软硬件及辅助设备批发;仪器仪表修理;电子专用材料销售;专用设备修理;工业机器人安装、维修;工程管理服务;电子元器件零售;互联网销售（除销售需要许可的商品）。',
}
