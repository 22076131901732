<template>
	<!-- 顶部导航栏手机端 --- zhangqin 2024.3.15 -->
	<div class="topNavPhone">
		<div class="tnp-top flex">
			<img src="@/assets/imgs/homeLogo.png" />
			<div class="tnpt-right flex">
				<div
					class="rnptr-heng flex"
					@click="navTabsShow = !navTabsShow"
				>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div class="tnpt-select" v-if="navTabsShow">
					<div class="tnpts-top">
						<div class="tnpts-input flex">
							<input
								type="text"
								:placeholder="$t('m.您想查询的产品是什么')"
								v-model="searchVal"
								@keyup.enter="searchChange"
							/>
							<div class="tnpts-btn flex" @click="searchChange">
								<span>{{ $t('m.search') }}</span>
								<img src="@/assets/imgs/topNavSearch.png" />
							</div>
						</div>
					</div>
					<div class="tnpts-cont">
						<div class="tnpts-model" @click="toHome"></div>
						<el-collapse v-model="activeName" accordion>
							<el-collapse-item
								:title="$t('m.首页')"
								name="1"
								disabled
							>
							</el-collapse-item>
							<el-collapse-item :title="$t('m.产品')" name="2">
								<div class="tnptsTwo-cont">
									<div
										v-for="(item, index) in twoProList"
										:key="index"
									>
										<span v-if="index != 0">-</span>
										<div @click="toProduct(item)">
											{{ item }}
										</div>
									</div>
								</div>
							</el-collapse-item>
							<el-collapse-item
								:title="$t('m.解决方案')"
								name="3"
							>
								<div
									@click="routerJump('电池行业', '/battery')"
								>
									{{ $t('m.电池行业') }}
								</div>
								-
								<div @click="routerJump('汽车行业', '/car')">
									{{ $t('m.汽车行业') }}
								</div>
								-
								<div @click="routerJump('一般行业', '/sameAs')">
									{{ $t('m.一般行业') }}
								</div>
							</el-collapse-item>
							<el-collapse-item :title="$t('m.支持')" name="4">
								<div
									@click="
										routerJump(
											'设备故障处理',
											'/problemHandling'
										)
									"
								>
									{{ $t('m.设备故障处理') }}
								</div>
								-
								<div
									@click="
										routerJump('证书&认证', '/certificate')
									"
								>
									{{ $t('m.证书认证') }}
								</div>
								-
								<div
									@click="
										routerJump('设备资料', '/information')
									"
								>
									{{ $t('m.设备资料') }}
								</div>
							</el-collapse-item>
							<el-collapse-item :title="$t('m.关于')" name="5">
								<div @click="routerJump('关于我们', '/about')">
									{{ $t('m.关于我们') }}
								</div>
								-
								<div
									@click="
										routerJump('联系我们', '/contactUs')
									"
								>
									{{ $t('m.联系我们') }}
								</div>
								-
								<div
									@click="routerJump('联系经销商', '/dealer')"
								>
									{{ $t('m.联系经销商') }}
								</div>
							</el-collapse-item>
							<el-collapse-item
								:title="$t('m.language')"
								name="6"
							>
								<div @click="changeLangEvent('zh-cn')">
									中文
								</div>
								-
								<div @click="changeLangEvent('eng')">
									English
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'topNav',
	data() {
		return {
			activeName: '',
			navTabsShow: false,
			twoProList: [],
			searchVal: '',
		}
	},
	created() {
		if (!sessionStorage.getItem('language')) {
			window.sessionStorage.setItem('language', 'zh-cn')
		}
		this.getToken()
	},
	methods: {
		// 语言切换
		changeLangEvent(command) {
			this.$i18n.locale = command
			sessionStorage.setItem('language', command)
			this.$router.go(0)
		},
		routerJump(name, path) {
			if (this.$route.path != path) {
				this.$router.push(path)
			}
			this.navTabsShow = false
		},
		// 搜索框
		searchChange() {
			if (!this.searchVal) {
				this.$message.error('请输入搜索内容!')
				return
			}
			if (this.$route.path != '/product') {
				this.$router.push({
					path: '/product',
					query: {
						name: this.searchVal,
					},
				})
			} else {
				if (this.$route.query.name == this.searchVal) {
					this.$router.go(0)
				} else {
					this.$router.push({
						path: '/product',
						query: {
							name: this.searchVal,
						},
					})
					this.$router.go(0)
				}
			}
			this.navTabsShow = false
			this.searchVal = ''
		},
		// 跳转产品
		toProduct(item) {
			if (this.$route.path != '/product') {
				this.$router.push({
					path: '/product',
					query: {
						two: item,
					},
				})
			} else {
				if (this.$route.query.tree == item) {
					this.$router.go(0)
				} else {
					this.$router.push({
						path: '/product',
						query: {
							two: item,
						},
					})
					this.$router.go(0)
				}
			}
			this.navTabsShow = false
		},
		// 请求用户token和产品数据
		async getToken() {
			let { data } = await this.axios.post(
				'api/open-apis/auth/v3/tenant_access_token/internal',
				// `${this.$store.state.feishu}/open-apis/auth/v3/tenant_access_token/internal`,
				{
					app_id: 'cli_a5782ac8eefc100d',
					app_secret: 'qP7u5xpm91bIBwd4gFX7TeZChbxaa6sY',
				},
				{
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
				}
			)
			sessionStorage.setItem('token', data.tenant_access_token)
			sessionStorage.setItem('expirationTime', data.expire)
			// if (sessionStorage.getItem('expirationTime') < 120) {

			if (window.sessionStorage.getItem('language') == 'zh-cn') {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)
				let list = res.data.data.items
				let twoProList = []
				list.map((item) => {
					if (item.fields['二级分类']) {
						twoProList.push(item.fields['二级分类'].value[0])
					}
				})
				twoProList = Array.from(new Set(twoProList))
				sessionStorage.setItem('twoProList', JSON.stringify(twoProList))
				this.twoProList = twoProList
			} else {
				let res = await this.axios.post(
					`api/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_idEN}/records/search`,
					// `${this.$store.state.feishu}/open-apis/bitable/v1/apps/${this.$store.state.app_token}/tables/${this.$store.state.table_id}/records/search`,
					{
						view_id: this.$store.state.view_id,
					},
					{
						headers: {
							'Content-Type': 'application/json; charset=utf-8',
							Authorization: `Bearer ${data.tenant_access_token}`,
						},
					}
				)
				let list = res.data.data.items
				let twoProList = []
				list.map((item) => {
					if (item.fields['二级分类EN']) {
						twoProList.push(item.fields['二级分类EN'].value[0].text)
					}
				})
				twoProList = Array.from(new Set(twoProList))
				sessionStorage.setItem('twoProList', JSON.stringify(twoProList))
				this.twoProList = twoProList
			}
		},
		toHome() {
			if (this.$route.path != '/') {
				this.$router.push('/')
			}
			this.navTabsShow = false
		},
	},
}
</script>
<style scoped lang="scss">
.topNavPhone {
	width: 100%;
	.tnp-top {
		justify-content: space-between;
		width: 100%;
		padding-left: 26px;
		box-sizing: border-box;
		img {
			width: 92px;
			height: 19px;
		}
		.tnpt-right {
			background-color: #284087;
			width: 66px;
			height: 51px;
			justify-content: center;
			position: relative;
			.rnptr-heng {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				div {
					width: 16px;
					height: 2px;
					border-radius: 7px;
					background-color: #ffffff;
					margin: 1px 0;
				}
			}
			.tnpt-select {
				position: absolute;
				width: 294px;
				background-color: #f5f5f5;
				right: 0;
				top: 51px;
				z-index: 999;
				.tnpts-top {
					padding: 20px;
					box-sizing: border-box;
					.tnpts-input {
						background-color: #ffffff;
						width: 100%;
						height: 38px;
						justify-content: space-between;
						padding: 5px;
						box-sizing: border-box;
						input {
							font-size: 12px;
							padding-right: 8px;
							box-sizing: border-box;
							flex: 1;
						}
						.tnpts-btn {
							width: 54px;
							height: 28px;
							background-color: #284087;
							font-weight: 400;
							font-size: 9px;
							color: #ffffff;
							justify-content: center;

							img {
								width: 9px;
								height: 9px;
								margin-left: 7px;
							}
						}
					}
				}
				.tnpts-cont {
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					background-color: #ffffff;
					position: relative;
					.tnpts-model {
						width: 100%;
						background-color: transparent;
						height: 49px;
						position: absolute;
						left: 0;
						z-index: 999;
					}
					.tnptsTwo-cont {
						max-height: 100px;
						overflow-y: auto;
					}
					::v-deep .el-collapse-item__header {
						color: #808080;
						font-size: 14px;
					}
					::v-deep .is-active {
						color: #474747;
						font-weight: bold;
					}
					::v-deep .el-collapse-item__content {
						font-weight: 500;
						font-size: 12px;
						color: #727272;
					}
				}
			}
		}
	}
}
</style>
