import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/rem'
import '@/assets/style/base.scss'
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.axios = axios

Vue.use(ElementUI)
Vue.use(VueI18n)

const i18n = new VueI18n({
	// 默认中文
	locale: sessionStorage.getItem('language') || 'zh-cn',
	messages: {
		'zh-cn': require('./common/zh'), // 中文语言包
		'eng': require('./common/en'), // 英文语言包
	},
})

new Vue({
	router,
	store,
	render: (h) => h(App),
	el: '#app',
	i18n, // 不要忘记
	store,
	router,
	template: '<App/>',
	components: { App },
}).$mount('#app')
