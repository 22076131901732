export const m = {
	首页: 'Home',
	产品: 'Product',
	解决方案: 'Solution',
	支持: 'Support',
	关于: 'About',
	search: 'Search',
	language: 'English',
	高效: 'Efficient',
	极致: 'Perfection',
	精准: 'Precision',
	了解更多: 'Learn more',
	年经验: 'Years of experience',
	荣誉资质: 'Honor and qualification',
	专利产品: 'Patented product',
	成功产品: 'Successful product',
	了解详情: 'Learn more',
	更多: 'More',
	关注我们: 'Follow us',
	快捷导航: 'Quick navigation',
	联系方式: 'Contact information',
	我们支持可为您提供24小时全天候的帮助:
		'Our support is available 24 hours a day.',
	地址: '1/F,C17-1 Building,38 Datagu Zhong Road,Xiantao Street,Yubei District,Chongqing',
	电池行业: 'Battery industry',
	汽车行业: 'Automobile industry',
	一般行业: 'General industry',
	设备故障处理: 'Equipment fault handling',
	证书认证: 'Certificates & Certifications',
	设备资料: 'Equipment data',
	关于我们: 'About us',
	联系我们: 'Contact us',
	联系经销商: 'Contact the dealer',
	全部: 'All',
	您想查询的产品是什么:
		'What is the product / item number you want to inquire about?',
	您当前的位置: 'Your current location',
	筛选: 'Filtrate',
	特定目录: 'Specific directory',
	显示更多: 'Show more',
	显示更少: 'Show less',
	选择您的产品: 'Select your product',
	产品编号: 'Product number',
	品类: 'Category',
	新闻资讯: 'News information',
	新闻详情: 'News details',
	发布时间: 'Release time',
	来源: 'Source',
	产品中心: 'Product center',
	推荐产品: 'Recommended product',
	产品简介: 'Product introduction',
	原厂编号: 'Original number',
	关联编号: 'Associated number',
	关联产品: 'Associated product',
	作为国际化的市场领导者:
		'Vitoco Fluid Technology (Chongqing) Co., Ltd. is located in Yubei District, Chongqing, providing fluid equipment and accessories for automotive, lithium battery and general industrial production',
	电子产品销售:
		'Sales of electronic products; Electronic components wholesale; Electrical instrument sales; Wholesale of computer hardware, software and auxiliary equipment; Instrument repair; Sales of electronic special materials; Special equipment repair; Industrial robot installation and maintenance; Project management services; Electronic components retail; Internet sales (except sales of goods that require permission).',
}
