<template>
	<div id="app">
		<topNav v-if="size == 'PC'"></topNav>
		<topNavPhone v-if="size == 'PHONE'"></topNavPhone>
		<div>
			<router-view />
		</div>
		<appFooter v-if="size == 'PC'"></appFooter>
		<appFooterPhone v-if="size == 'PHONE'"></appFooterPhone>
	</div>
</template>

<script>
import topNav from '@/components/topNav'
import topNavPhone from '@/components/topNavPhone'
import appFooter from '@/components/appFooter'
import appFooterPhone from '@/components/appFooterPhone'

export default {
	name: 'app',
	components: {
		topNav,
		topNavPhone,
		appFooter,
		appFooterPhone,
	},
	data() {
		return {
			size: sessionStorage.getItem('size'),
		}
	},
	created() {
		//全局监听，页面刷新的时候将store里state的值存到sessionStorage中，然后从sessionStorage中获取，再赋值给store。
		//然后再把session里面存的删除即可，相当于中间件的作用。
		//在页面加载时读取sessionStorage里的状态信息
		if (sessionStorage.getItem('store')) {
			this.$store.replaceState(
				Object.assign(
					{},
					this.$store.state,
					JSON.parse(sessionStorage.getItem('store'))
				)
			)
			sessionStorage.removeItem('store')
		}
		//在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', () => {
			sessionStorage.setItem('store', JSON.stringify(this.$store.state))
		})
	},
	mounted() {},
	watch: {},
	methods: {},
}
</script>
<style lang="scss"></style>
