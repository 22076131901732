import request from '@/utils/request'

// 通过type查询解决方案 支持两个模块所有数据
export function getallInfo(data) {
	return request({
		url: '/web/content/query',
		method: 'post',
		data,
	})
}

// 获取两个模板详情
export function allinfoDetaile(params) {
	return request({
		url: '/web/content/detail',
		method: 'get',
		params,
	})
}

// 获取经销商
export function getDealer(data) {
	return request({
		url: '/web/agent/query',
		method: 'post',
		data,
	})
}

// 新闻列表获取
export function getNews(data) {
	return request({
		url: '/web/content/query',
		method: 'post',
		data,
	})
}

// 新闻列表详情
export function newDetailes(params) {
	return request({
		url: '/web/content/detail',
		method: 'get',
		params,
	})
}

// 首页banner图
export function getBanner(data) {
	return request({
		url: '/web/banner/query',
		method: 'post',
		data,
	})
}

// 关于我们
export function getaboutus(params) {
	return request({
		url: '/web/aboutUs/detail/byLng',
		method: 'get',
		params,
	})
}
