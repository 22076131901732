<template>
	<!-- 底部 --- zhangqin 2024.3.13 -->
	<div class="app-footer">
		<div class="footer-cont">
			<div class="container">
				<div class="ft-gzwm">{{ $t('m.关注我们') }}</div>
				<img class="ft-ewm" src="@/assets/imgs/footerEWM.png" />
				<div class="ft-kjdh">{{ $t('m.快捷导航') }}</div>
				<div class="ft-tabs flex">
					<div
						class="ftt-item flex"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPage(item)"
					>
						<span class="ftti-heng" v-if="index != 0"></span>
						<span>{{ item.name }}</span>
					</div>
				</div>
				<div class="ft-lxfs">{{ $t('m.联系方式') }}</div>
				<div class="ft-zhichi">
					{{ $t('m.我们支持可为您提供24小时全天候的帮助') }}
				</div>
				<div class="ft-phone flex">
					<div class="ftp-item flex">
						<img src="@/assets/imgs/footerPhone.png" />
						<span>{{ aboutusObj.phone }}</span>
					</div>
					<div class="ftp-item flex">
						<img src="@/assets/imgs/footerEmail.png" />
						<span>{{ aboutusObj.email }}</span>
					</div>
					<div class="ftp-item flex">
						<img src="@/assets/imgs/footerInter.png" />
						<span>{{ aboutusObj.web }}</span>
					</div>
					<div class="ftp-item flex">
						<img src="@/assets/imgs/footerMap.png" />
						<span>{{ aboutusObj.address }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bom">
			维多科流体技术（重庆）有限公司
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>渝ICP备2024027752号-1</a
			>
		</div>
	</div>
</template>

<script>
import { getaboutus } from '@/api/home'
export default {
	name: 'appFooter',
	data() {
		return {
			navList: [
				{ name: this.$t('m.首页'), value: 1, path: '/' },
				{ name: this.$t('m.产品'), value: 2, path: '/product' },
				{ name: this.$t('m.解决方案'), value: 3, path: '/battery' },
				{ name: this.$t('m.支持'), value: 4, path: '/information' },
				{ name: this.$t('m.关于'), value: 5, path: '/about' },
			],
			aboutusObj: {},
		}
	},
	created() {
		this.getaboutusData()
	},
	methods: {
		// 获取关于我们内容
		getaboutusData() {
			let params = {
				lng: window.sessionStorage.getItem('language'),
			}
			getaboutus(params).then((res) => {
				this.aboutusObj = res
			})
		},
		// 跳转页面
		toPage(item) {
			this.$store.commit('navbgshow', item.value)
			if (this.$route.path != item.path) {
				this.$router.push(item.path)
			}
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
	},
}
</script>
<style scoped lang="scss">
.app-footer {
	width: 100%;

	.footer-cont {
		width: 100%;
		background: url('@/assets/imgs/footerBg.png') no-repeat;
		background-size: cover;
		padding-top: 40px;
		padding-bottom: 44px;
		border-top: 1px solid #f5f5f5;
		border-bottom: 1px solid #f5f5f5;
		box-sizing: border-box;
		.ft-gzwm {
			font-size: 20px;
			color: #474747;
			font-weight: bold;
		}
		.ft-ewm {
			width: 186px;
			height: 186px;
			margin-top: 15px;
		}
		.ft-kjdh {
			font-size: 20px;
			color: #474747;
			font-weight: bold;
			margin-top: 40px;
		}
		.ft-tabs {
			margin-top: 16px;
			.ftt-item {
				font-size: 16px;
				color: #474747;
				font-weight: 500;
				cursor: pointer;
				.ftti-heng {
					width: 6px;
					height: 1px;
					background-color: #474747;
					display: inline-block;
					margin: 0 20px;
				}
			}
		}
		.ft-lxfs {
			font-size: 20px;
			color: #474747;
			font-weight: bold;
			margin-top: 48px;
		}
		.ft-zhichi {
			font-size: 16px;
			color: #474747;
			font-weight: 500;
			margin-top: 10px;
		}
		.ft-phone {
			margin-top: 28px;
			.ftp-item {
				font-size: 16px;
				font-weight: 500;
				color: #474747;
				margin-right: 20px;
				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}
	}
	.footer-bom {
		padding: 20px 0;
		text-align: center;
		font-size: 14px;
		color: #474747;
		font-weight: 500;
		a {
			font-size: 14px;
			color: #474747;
		}
		a:hover {
			color: #284087;
		}
	}
}
</style>
