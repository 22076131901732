import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// size: 'PC',
		navbg: 1,
		navShow: '',
		app_token: 'Spbxbx3Rva8NUAsVBetcZEFRnnh',
		table_id: 'tblwZSinBYu7DYi6',
		table_idEN: 'tblXq8pBOHp0CGI7',
		view_id: 'vewlq5P9Ov',
		productList: [], //产品数据
		feishu: 'https://open.feishu.cn',
	},
	getters: {},
	mutations: {
		// 获取产品数据
		productListChange(state, data) {
			state.productList = data
		},
		navbgshow(state, ids) {
			//首页导航切换状态
			state.navbg = ids
		},
		navHoverShow(state, ids) {
			state.navShow = ids
		},
		// sizeChange(state, type) {
		// 	//首页导航背景图切换状态
		// 	state.size = type
		// },
	},
	actions: {},
	modules: {},
})
